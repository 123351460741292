import React from "react"
import { css } from "@emotion/core"
import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Heading, Text } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 4, mySubpage = 0 }) => {
  const data = useStaticQuery(graphql`
      query {
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_16x9_VIideo9_CTM_V2_3bit" }
          ) {
              name
              publicURL
          }
      }
  `)

  const { video } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={
          <span>
            In a study of 400 women executives from four continents, 94% had
            played sports as girls.<Source>34</Source>
          </span>
        }
        text={
          "The majority of these leaders said that skills learned in sports helped them be more successful in their careers."
        }
        videoSrc={video.publicURL}
        videoLabel={video.name}
      />

      <Infobox uppercase>
        <Heading mb={5}>
          There is an indisputable connection between sports and success.
        </Heading>
        Playing on a team is not just about knowing how to shoot, dribble, and
        pass. It’s also about knowing how to show up on time, how to be a
        teammate, how to stay focused under stress, how to win (and lose)
        graciously, how to set and achieve goals, and how to follow directions.
        <br/>
        <br/>
        “I’m a big believer that sports are a great place for young people to
        learn courage. Sports force you to make decisions in real time and live
        with them. There are consequences to winning and losing...I don’t
        believe it is a coincidence that so many successful women executives
        today were active—and in many cases are still active—in sports. — Meg
        Whitman<Source>35</Source>
      </Infobox>

      <InfoboxDetailed
        headerText={
          "In order to recognize skill-building opportunities, you must remember that a girl’s behavior is a result of her past and current experiences."
        }
        maxWidth={"100%"}
        headerMaxWidth={"desktop"}
        contentMaxWidth={"mediumContent"}
      >
        <Flex flexDirection={["column"]} mt={4}>
          <Box>
            <Text mt={0}>
              As a master skill-builder, you as a coach are uniquely set up to
              teach all types of skills to your athletes. Once these skills are
              learned, you will uplift girls on and off the field. When you look
              at the behavior of a girl as an opportunity for skill-building,
              you are able to teach so much more than just the technical side of
              sports.
            </Text>

            <Text>
              Behavior tells a story, and a girl’s actions give insight into
              what she’s going through. The environment in which she lives, the
              people who surround her, and the cultural influences to which
              she’s exposed all impact her behavior.
            </Text>
            <Text>
              But you can’t{" "}
              <span
                css={css`
                  font-family: "AdineuePRORegularItalicWeb";
                `}
              >
                define
              </span>{" "}
              a player by her behavior, just like you wouldn’t define a player
              by her ability to score a goal. Instead, you as a coach need to
              recognize that, as with any ability, behavior can be changed,
              strengthened, and developed over time. Think back to the{" "}
              <span
                css={css`
                  font-family: "AdineuePROBoldWeb";
                `}
              >
                growth mindset
              </span>
              .
            </Text>
            <Text>
              As a coach, you must always look at your players through this
              lens, with an understanding of what you see and an acknowledgement
              of how this ties back to things you cannot see.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>
      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 4, 0)
